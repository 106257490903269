<template>
  <v-container style="margin-right: -40px;">

    <v-row>

      <v-col class="d-none d-sm-block" sm="1"></v-col>

      <v-col sm="10">
        
        <div
          style="
          max-width: 100%;
           margin: auto;
          padding-bottom: 20px;
          "
        >


          <v-card style="margin-bottom: 15px; box-shadow: 0 0 18px #0000003d background: #95aaea;
                background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" elevation="7" 
              >
            <div style="padding: 10px" class="overline">
                <h4>Manual Integration API (Open API)</h4>
            </div>
          </v-card>

          
          <v-card style="margin-bottom: 10px; box-shadow: 0 0 18px #0000003d; border-radius: 5px; padding: 13px" elevation="5">
            <div>
              <div class="elementor-column-wrap elementor-element-populated">
                <div class="elementor-widget-wrap">
                  <div 
                    class="elementor-element elementor-element-d3c92f0 elementor-widget elementor-widget-text-editor"
                    data-id="d3c92f0"
                    data-element_type="widget"
                    data-widget_type="text-editor.default">

                      <div class="elementor-widget-container">
                        <div class="elementor-text-editor elementor-clearfix">

                          <div style="background: #95aaea; padding-top: 5px; padding-bottom: 5px; padding-left: 7px; width: 320px; margin-bottom: 10px;
                            background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" 
                            >A) What parameters to send to LCPay
                          </div>
                          <br>
                          <p>
                            Instruction for API integration with LCPay input parameter
                          </p>
                          <p style="font-weight: bold;">
                            The table below lists the details of each parameter:
                          </p>

                          <div style="border: 1px solid #c8ced3;  height: auto; margin-bottom: 50px">
                            <v-simple-table
                                fixed-header

                              >
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">
                                        Item
                                      </th>
                                      <th class="text-left">
                                        Detail
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="text-left">
                                        detail
                                      </th>
                                      <th class="text-left">
                                        This is the description that will be displayed when making the payment. The maximum length is 500 characters. Any underscore (_) characters will be converted to space. Example Shopping_cart_id_30. Only A to Z, a to z, 1 to 0, dot, comma, dash and underscore allowed.
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="text-left">
                                        amount
                                      </th>
                                      <th class="text-left">
                                        The amount to charge the buyer. The format must be in 2 decimal places. Example 25.50.
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="text-left">
                                        order_id
                                      </th>
                                      <th class="text-left">
                                        This is the id used to identify the shopping cart when LCPay redirects the buyer back after the payment was made. The maximum length is 100 characters. Example 3432D4. Only A to Z, a to z, 1 to 0 and dash allowed.
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="text-left">
                                        hash
                                      </th>
                                      <th class="text-left">
                                        The is the data to ensure the data integrity has passed from the merchant’s shopping cart to LCPay. Refer to How to generate the secure hash section for more info.
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="text-left">
                                        name
                                      </th>
                                      <th class="text-left">
                                        This is the name to populate in the payment form so that customers do not have to key in their names. This is optional and does not have to be a part of the hash. Customers may overwrite the value in the payment form.
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="text-left">
                                        email
                                      </th>
                                      <th class="text-left">
                                        This is the email address to populate in the payment form so that customers do not have to key in their emails. This is optional and does not have to be a part of the hash. Customers may overwrite the value in the payment form.
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="text-left">
                                        phone
                                      </th>
                                      <th class="text-left">
                                        This is the phone to populate in the payment form so that customer do not have to key in their phone. This is optional and not have to be part of the hash. Customer is able to overwrite the value in payment form.
                                      </th>
                                    </tr>
                                  </thead>
                                </template>
                            </v-simple-table>
                          </div>

                          <div style="background: #95aaea; padding-top: 5px; padding-bottom: 5px; padding-left: 7px; width: 320px; margin-bottom: 10px;
                            background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" 
                            >B) How to send the parameters to LCPay 
                          </div>

                          <br>

                          <p>
                            Parameter must be send in JSON Object format and encoded with base64 encoding
                          </p>
                          <p style="font-weight: bold;">
                            Example of JSON object before base64 encoding : 
                          </p>

                          <div style="border: 1px solid #c8ced3; width:auto ;padding: 10px; margin-bottom: 20px;overflow:auto">
                            <pre style="font-size: 12px">
 {
    "merchant_id": "BVWS42176",
    "detail": 
      [
        {
          "name": "botol air",
          "quantity": 1,
          "price": 1.2
        },
        {
          "name": "topi",
          "quantity": 1,
          "price": 12
        }
      ],
    "amount": 1.2,
    "order_id": "T202020",
    "hash": "fd75d4212b731f48c1e77fadbd6b3d13279230ead9d04a11d6ed73eb3bf4e8c2",
    "name": "Mohd Badrul Amin",
    "email": "badrulryushi@gmail.com",
    "phone": "0184043881"
  }                             
                            </pre>  
                          </div>

                          <p style="font-weight: bold;">
                            Example of input parameter after base64 encoding :
                          </p>
                          <br>
                          <p style=" margin-top: -20px; width: 430px; padding: 10px; border: 1px solid #c8ced3; font-size: 15px">
                            ewoJIm1lcmNoYW50X2lkIjogIkJWV1M0MjE3NiIsCgkiZGV0YWlsIjogW3sKCQkJIm5hbWUiOiAiYm90b2wgYWlyIiwKCQkJInF1YW50aXR5IjogMSwKCQkJInByaWNlIjogMS4yCgkJfSwKCQl7CgkJCSJuYW1lIjogInRvcGkiLAoJCQkicXVhbnRpdHkiOiAxLAoJCQkicHJpY2UiOiAxMgoJCX0KCV0sCgkiYW1vdW50IjogMS4yLAoJIm9yZGVyX2lkIjogIlQyMDIwMjAiLAoJImhhc2giOiAiZmQ3NWQ0MjEyYjczMWY0OGMxZTc3ZmFkYmQ2YjNkMTMyNzkyMzBlYWQ5ZDA0YTExZDZlZDczZWIzYmY0ZThjMiIsCgkibmFtZSI6ICJNb2hkIEJhZHJ1bCBBbWluIiwKCSJlbWFpbCI6ICJiYWRydWxyeXVzaGlAZ21haWwuY29tIiwKCSJwaG9uZSI6ICIwMTg0MDQzODgxIgp9Cg==
                          </p>

                          <br>
                          <p style="font-weight: bold;">
                            Input parameter must be sent to this address :
                          </p>
                          <p style="width: 430px; padding: 10px; border: 1px solid #c8ced3; font-size: 15px">
                            https://payment.lcpay.my/integration/(encoded-parameter)
                          </p>

                          <br>
                          <p style="font-weight: bold;">
                            Final input url example :
                          </p>
                          <p style=" width: 430px; padding: 10px; border: 1px solid #c8ced3; font-size: 15px">
                            https://payment.lcpay.my/integration/ewoJIm1lcmNoYW50X2lkIjogIkJWV1M0MjE3NiIsCgkiZGV0YWlsIjogW3sKCQkJIm5hbWUiOiAiYm90b2wgYWlyIiwKCQkJInF1YW50aXR5IjogMSwKCQkJInByaWNlIjogMS4yCgkJfSwKCQl7CgkJCSJuYW1lIjogInRvcGkiLAoJCQkicXVhbnRpdHkiOiAxLAoJCQkicHJpY2UiOiAxMgoJCX0KCV0sCgkiYW1vdW50IjogMS4yLAoJIm9yZGVyX2lkIjogIlQyMDIwMjAiLAoJImhhc2giOiAiZmQ3NWQ0MjEyYjczMWY0OGMxZTc3ZmFkYmQ2YjNkMTMyNzkyMzBlYWQ5ZDA0YTExZDZlZDczZWIzYmY0ZThjMiIsCgkibmFtZSI6ICJNb2hkIEJhZHJ1bCBBbWluIiwKCSJlbWFpbCI6ICJiYWRydWxyeXVzaGlAZ21haWwuY29tIiwKCSJwaG9uZSI6ICIwMTg0MDQzODgxIgp9Cg==
                          </p>

                          <br>

                          <p style="font-weight: bold;">
                            How to generate the secure hash
                          </p>

                          <p style="font-size: 15px">
                            Concantenate variable following this order, then hash it using sha256
                          </p>
                          <p style="font-size: 15px">
                            string = token|detail|amount|orderid
                          </p>

                          <p style="font-weight: bold;">
                            PHP Example :
                          </p>

                           <div style="border: 1px solid #c8ced3; width:auto ;padding: 10px; margin-bottom: 20px;overflow:auto">
<pre style="font-size: 12px">
$hash = hash("sha256",$token.'|'.json_encode($detail).'|'.$amount.'|'.$order_id);
</pre>
                          </div>

                          <p style="font-size: 15px">
                            *received parameter (receive at return url)*
                          </p>

                          <p style="font-weight: bold;">
                            Javascript Example :
                          </p>

                          <div style="border: 1px solid #c8ced3; width:auto ;padding: 10px; margin-bottom: 20px;overflow:auto">
                            <pre style="font-size: 12px">
const combine = token + '|' + JSON.stringify(details) + '|' + parseFloat(this.totalPrice) + '|' + data.data._id
const payload = new TextEncoder().encode(combine)
const digest = window.crypto.subtle.digest('SHA-256', payload).then(function (hash) {
    const newPayload = 
      {
      merchant_id: 'BVWS42176',
      detail: details,
      amount: parseFloat(self.totalPrice),
      order_id: data.data._id,    
      hash: hashs,
      name: name,
      email: email,
      phone: mobile,
      }
    const payloadFinal = btoa(JSON.stringify(newPayload))

  window.location.href = 'https://payment.lcpay.my/integration/' + payloadFinal
})                           
</pre>
                            <br>
                          </div>

                           <div style="background: #95aaea; padding-top: 6px; padding-bottom: 6px; padding-left: 10px; width: 320px; margin-bottom: 10px;
                          background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                          background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                          background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                          filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" 
                          > c) How to set up LCpay return url
                          </div>

                           <br>

                          <p style="font-size: 15px;">
                              1. Log in to LCPay Merchant Dashboard.
                          </p>

                          <p style="font-size: 15px;">
                              2. Open "API" section in Integration menu.
                          </p>

                          <p style="font-size: 15px;">
                              3. Enter new return url and save.
                          </p>


                          <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                              <img style="border: 1px solid #c8ced3; max-width: 70%" :src="require(`../assets/menu_integration.jpg`)"/>
                          </div>

                          <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                              <img style="border: 1px solid #c8ced3; max-width: 90%" :src="require(`../assets/setup.jpg`)"/>
                          </div>

                          <br>

                          <div style="background: #95aaea; padding-top: 6px; padding-bottom: 6px; padding-left: 10px; width: 320px; margin-bottom: 10px;
                          background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                          background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                          background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                          filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" 
                          > d) How to receive LCPay return URL 
                          </div>

                          <br>

                          <p style="font-weight: bold;">
                            Example of raw received parameter :
                          </p>
                          <p style="width: 430px; padding: 10px; border: 1px solid #c8ced3; font-size: 15px">
                            eyJzdGF0dXNfaWQiOjEsIm9yZGVyX2lkIjoiVDIwMjAyMCIsIm1zZyI6InN1Y2Nlc3MiLCJ0cmFuc2FjdGlvbl9pZCI6IlctMTYyMTU5NjE0NDIwMCIsImhhc2giOiIxNzg2MDQ1NzNhYmZhMTU4ZGEzMjM2ZTY0MTI0MzUxYmE3ODcyZTQ0NGY1NmUzMGU1MWQ0MzJjNjNhNzEzMjhkIn0=
                          </p>
                          <br>

                          <p style="font-weight: bold;">
                            Example of base64 decoded parameter :
                          </p>

                          <div style="padding: 10px; border: 1px solid #c8ced3; width:auto; margin-bottom: 20px;overflow:auto">
                            <pre style="font-size: 12px">
{
  "status_id":1,
  "order_id":"T202020",
  "msg":"success",
  "transaction_id":"W-1621596144200",
  "hash":"178604573abfa158da3236e64124351ba7872e444f56e30e51d432c63a71328d"
}
                            </pre>
                          </div>

                          <p style="font-weight: bold;">
                            How to verify hash :
                          </p>


                          <p style="font-size: 15px">
                            string = token|statusid|orderid|transactionid
                          </p>
            
                          <p style="font-size: 15px">
                            Then you need to compare the hash(sha256) value that you have generated with the hash value sent from LCPay. If the value does not match then the data may have been tampered.
                          </p>

                        </div>
                      </div>

                  </div>
                </div>
              </div>
            </div>
          </v-card>
    
        </div>
      </v-col>

      <v-col class="d-none d-sm-block" sm="1"></v-col>



    </v-row>

  </v-container>
</template>

<script>
    export default {

    }
</script>

<style scoped>
.profile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 40px;
    color: rgb(167, 167, 167);
    font-weight: 600;
}
</style>