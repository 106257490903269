<template>
  <v-container style="margin-right: -40px"
    ><p>
      <strong>Getting Started: WordPress + WooCommerce</strong>
    </p>
    <p>
      <img
        border="0"
        height="250px"
        src="@/assets/woonwordp.png"
        alt="woocommerce"
      />
    </p>
    <p>
      <strong>A. Important Notes</strong>
    </p>
    <p>1. Please take note that this tutorial is for:</p>
    <ul>
      <li>Merchants with WordPress site.</li>

      <li>
        Merchants with WooCommerce Plugin already installed with the WordPress
        site.
      </li>
    </ul>
    <br />
    <p>
      2. Before you proceed with the tutorial, please take note of the steps
      that you will need to complete for the integration:
    </p>
    <ul>
      <li>Install the plugin to WordPress.</li>
      <li>
        Get your Merchant ID &amp; Secret Key for the integration from LCPay.
      </li>

      <li>
        Insert your Merchant ID &amp; Secret Key to your WooCommerce setting in
        WordPress.
      </li>

      <li>Finally, test your shopping cart</li>
    </ul>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>B. Download the LCPay Plugin</strong>
    </p>
    <p>
      1. You can install the plugin directly from your WordPress admin panel.
    </p>
    <p>2. The files name is lcpay-gateway.</p>
    <p>
      <a class="buttonDownload" href="files/lcpay-gateway.zip" download>
        <strong>Download Now!</strong>
      </a>
      <strong></strong>
    </p>
    <p>
      <strong>C. LCPay Shopping Cart Integration Link</strong>
    </p>
    <p>Next, you need to get your merchant ID and Secret Key.</p>
    <p>1. Login to your LCPay Dashboard.</p>
    <p>2. Go to Menu &gt; Integration &gt; API</p>
    <p>
      <img border="0" src="@/assets/woocommerce/01.png" />
    </p>
    <p>4. Copy the “Merchant ID” number and the “Token Key” number.</p>
    <p>
      <img border="0" src="@/assets/woocommerce/02.png" />
    </p>
    <p>
      5. Then you will need to fill in the return URL. This is the URL where
      LCPay will redirect the buyer to after the payment has been processed. The
      URL can be any of your site’s URL. For example:
    </p>
    <ul>
      <li>
        <a href="http://your_domain.com/checkout/">
          <strong><em>http://your_domain.com</em></strong>
        </a>
      </li>
    </ul>
    <p>to redirect the customer to “my account” page after payment.</p>
    <p>
      6. Please set the Woocommerce URL which is your Woocommerce website link:
    </p>
    <ul>
      <li>
        <a href="http://your_domain.com/checkout/">
          <strong><em>http://your_domain.com</em></strong>
        </a>
      </li>
    </ul>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>D. WooCommerce Setting</strong>
    </p>
    <p>
      Now, this is almost the last part of the set up. You need to setup your
      WooCommerce setting.
    </p>
    <p>1. Login to your WordPress site.</p>
    <p>
      2. Go to the WooCommerce Plugin Setting. Click Menu &gt; WooCommerce &gt;
      Settings
    </p>
    <p>
      <img
        border="0"
        src="@/assets/woocommerce/03.png"
        alt="Screen Shot 2015-07-05 at 2.16.20 AM"
      />
    </p>
    <p>3. Click on the “Checkout” tab, and click on the sub tab “LCPay.”</p>
    <p>
      <img border="0" src="@/assets/woocommerce/04.png" />
    </p>
    <p>4. You should see a setting page as displayed below.</p>
    <p>
      <img border="0" src="@/assets/woocommerce/07.jpg" />
    </p>
    <p>5. To enable LCPay Payment Gateway, tick on the checkbox.</p>
    <p>7. Insert any preferred title on the title field.</p>
    <p>8. Insert any preferred description on the description field.</p>
    <p>9. Insert your “Token Key” that you copied from your LCPay Dashboard.</p>
    <p>
      10. Insert your “Merchant ID” that you copied form your LCPay Dashboard.
    </p>
    <p>11. Next, click “Save Changes.”</p>
    <p>
      <strong>E. Testing your integration</strong>
    </p>
    <p>
      Now, this is the last part. You need to test whether your integration is
      working or not.
    </p>
    <p>1. Go to your Online Cart page.</p>
    <p>2. Select any product, and add to cart.</p>
    <p>3. Then, proceed to Checkout.</p>
    <p>
      4. You should see that the payment option to pay with LCPay is visible.
    </p>
    <p>
      5. Congratulations, your Online Business is now on another triumph level!
    </p>
    <p>
      <img border="0" src="@/assets/woocommerce/06.png" />
    </p>
    <p>
      <strong>Please take note that:</strong>
    </p>
    <p>
      · You must make sure that you have installed WooCommerce plugin before you
      install the LCPay plugin, otherwise, your WordPress site will be a blank
      screen.
    </p>
    <p>
      · If you want to uninstall your WooCommerce plugin, please uninstall the
      LCPay plugin first, then WooCommerce plugin otherwise, your WordPress site
      will be a blank screen.
    </p>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.buttonDownload {
  display: inline-block;
  position: relative;
  padding: 10px 25px;

  background-color: #4cc713;
  color: white;

  font-family: sans-serif;
  text-decoration: none;
  font-size: 0.9em;
  text-align: center;
  text-indent: 15px;
}

.buttonDownload:hover {
  background-color: #333;
  color: white;
}

.buttonDownload:before,
.buttonDownload:after {
  content: " ";
  display: block;
  position: absolute;
  left: 15px;
  top: 52%;
}

/* Download box shape  */
.buttonDownload:before {
  width: 10px;
  height: 2px;
  border-style: solid;
  border-width: 0 2px 2px;
}

/* Download arrow shape */
.buttonDownload:after {
  width: 0;
  height: 0;
  margin-left: 3px;
  margin-top: -7px;

  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: transparent;
  border-top-color: inherit;

  animation: downloadArrow 2s linear infinite;
  animation-play-state: paused;
}

.buttonDownload:hover:before {
  border-color: #4cc713;
}

.buttonDownload:hover:after {
  border-top-color: #4cc713;
  animation-play-state: running;
}

/* keyframes for the download icon anim */
@keyframes downloadArrow {
  /* 0% and 0.001% keyframes used as a hackish way of having the button frozen on a nice looking frame by default */
  0% {
    margin-top: -7px;
    opacity: 1;
  }

  0.001% {
    margin-top: -15px;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    margin-top: 0;
    opacity: 0;
  }
}
</style>